import React from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { constants, twcx } from 'helpers'

import { Text, type TextStyle } from 'components/dataDisplay'

import messages from './messages'


type Money = ({ amount: number } | { amountCents: number, amount?: number }) & {
  currencyCode?: string
}

type ProductPriceProps = {
  className?: string
  textStyle?: TextStyle
  oldPriceClassName?: string
  newPriceClassName?: string
  withNbsp?: boolean
  isTradingItem?: boolean
  uid?: string
} & AtLeastOne<{
  data: {
    discountPrice: Money
    standardPrice: Money
    membershipPrice?: Money
  }
  formattedData: {
    discountPrice: string
    standardPrice: string
    membershipPrice?: string
  }
}>

const mockUids = [ ...constants.mocks.membershipUids, ...constants.mocks.discountUids ]

// TODO should we use currencyCode from data? - added on 14.01.2022 by sonatskiy
const ProductPrice: React.FunctionComponent<ProductPriceProps> = (props) => {
  const {
    className,
    data: dataProp,
    formattedData,
    textStyle = 'h8',
    oldPriceClassName = 'font-normal',
    newPriceClassName = 'font-bold',
    withNbsp = true,
    isTradingItem,
    uid,
  } = props

  const isMembershipPriceEnabled = useFeatureIsOn(constants.features.membershipPrice)
  const showMembershipPrice = Boolean(isMembershipPriceEnabled && uid)

  let data = { ...dataProp }

  if (showMembershipPrice) {
    if (constants.mocks.membershipUids.includes(uid)) {
      data.membershipPrice = { amount: 20.20, amountCents: 2020, currencyCode: 'USD' }
    }
    else if (constants.mocks.discountUids.includes(uid)) {
      data.discountPrice = { amount: 22.22, amountCents: 2222, currencyCode: 'USD' }
    }
  }

  const intl = useIntl()

  const discountPrice = formattedData?.discountPrice || (data?.discountPrice ? intl.formatPrice(data.discountPrice) : null)
  const standardPrice = formattedData?.standardPrice || (data?.standardPrice ? intl.formatPrice(data.standardPrice) : null)
  const membershipPrice = formattedData?.membershipPrice || (data?.membershipPrice ? intl.formatPrice(data.membershipPrice) : null)

  if (!standardPrice && !discountPrice) {
    return null
  }

  if (showMembershipPrice && mockUids.includes(uid)) {
    return (
      <div className={twcx(!isTradingItem ? 'mt-16' : undefined, className, 'block')}>
        {
          Boolean(membershipPrice) && (
            <div>
              <Text style="p4" className="inline font-bold" color="gold-80" message={messages.memberPrice} />&nbsp;
              <Text style={isTradingItem ? 'p4' : 'h8'} className="inline" color="gold-80">{membershipPrice}</Text>
            </div>
          )
        }
        {
          Boolean(!membershipPrice && discountPrice) && (
            <div>
              <Text style="p4" className="inline font-bold" message={messages.salePrice} />&nbsp;
              <Text style="h8" className="inline">{discountPrice}</Text>
            </div>
          )
        }
        {
          !isTradingItem && (
            <Text style="p4" className="mt-8" message={{ ...messages.regularPrice, values: { price: standardPrice } }} html />
          )
        }
      </div>
    )
  }

  const finalPrice = discountPrice || standardPrice

  if (finalPrice !== standardPrice) {
    return (
      <Text className={className} style={textStyle}>
        <s className={cx(oldPriceClassName)} aria-label={intl.formatMessage(messages.old)} data-testid="oldPrice">{standardPrice}</s>
        {withNbsp && ' '}
        <strong className={cx(newPriceClassName)} aria-label={intl.formatMessage(messages.new)} data-testid="price">{finalPrice}</strong>
      </Text>
    )
  }

  return (
    <Text className={cx(className, newPriceClassName)} style={textStyle} aria-label={intl.formatMessage(messages.price)} data-testid="price">
      {finalPrice}
    </Text>
  )
}


export default React.memo(ProductPrice)
