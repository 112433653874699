import React from 'react'
import { useScrollingFrame } from 'hooks'
import { useEntry } from 'intersection-observer'
import { useDevice } from 'device'

import { WidthContainer } from 'components/layout'
import { Image, Text } from 'components/dataDisplay'

import messages from './messages'

import brandsImage from './images/brands.png?src-loader'


const MobileView = () => {
  const [ ref, entry ] = useEntry()
  const isVisible = entry?.isIntersecting

  const imageRef = useScrollingFrame({
    horizontalOffset: 16,
    verticalOffset: 16, // to beautify image view when it reaches top border of viewport
    isVisible,
  })

  return (
    <div ref={ref} className="mt-24">
      <div className="relative overflow-hidden pt-24">
        <div ref={imageRef} className="absolute left-0 top-0 mx-16 w-[1116rem]">
          <Image
            className="w-full"
            src={brandsImage}
            width={1116}
            alt="Brands"
          />
        </div>
      </div>
      <Text className="mt-24 text-center" message={messages.text} style="p3" />
    </div>
  )
}

const BrandsRow: React.FunctionComponent = () => {
  const { isMobile } = useDevice()

  if (isMobile) {
    return <MobileView />
  }

  return (
    <WidthContainer className="mt-32 text-center">
      <Image
        className="w-full"
        src={brandsImage}
        width={1136}
        alt="Brands"
      />
      <Text className="mt-32" message={messages.text} style="p1" />
    </WidthContainer>
  )
}


export default BrandsRow
