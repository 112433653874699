import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { twcx } from 'helpers'

import { AspectContainer } from 'components/layout'
import { Image } from 'components/dataDisplay'

import BaseContent from './components/BaseContent/BaseContent'


type EditorialBlockProps = {
  className?: string
  blockClassName?: string
  textBlockClassName?: string
  bgImage: string | { mobile: string, desktop: string }
  imageFirst?: boolean // by default on mobiles image goes after content block, when value is "true" the image goes first
  imageLazy?: boolean
  'data-testid'?: string
}

type EditorialBlockComponent = React.CFC<EditorialBlockProps> & {
  BaseContent: typeof BaseContent
}

const EditorialBlock: EditorialBlockComponent = (props) => {
  const { children, className, blockClassName, textBlockClassName, bgImage, imageFirst, imageLazy, 'data-testid': dataTestId } = props

  const { isMobile } = useDevice()

  const bgImageSrc = typeof bgImage === 'string' ? bgImage : (isMobile ? bgImage?.mobile : bgImage?.desktop)

  const rootClassName = cx(className, 'relative flex', imageFirst ? 'flex-col-reverse' : 'flex-col')

  return (
    <div className={rootClassName} data-testid={dataTestId}>
      <AspectContainer className="bg-light-beige" aspect={isMobile ? 416 / 312 : 1440 / 688}>
        <Image
          src={bgImageSrc}
          alt=""
          sizes={isMobile ? '100vw' : '(min-width: 1440px) 1440px, (min-width: 1080px) 100vw, 1080px'}
          lazy={imageLazy}
        />
      </AspectContainer>
      <div className={twcx(isMobile ? null : 'absolute right-0 top-0 z-10 flex h-full max-w-[690rem] items-center', blockClassName)}>
        <div className={twcx('bg-black text-white', isMobile ? 'px-40 pb-40 pt-56' : 'p-72', textBlockClassName)}>
          {children}
        </div>
      </div>
    </div>
  )
}

EditorialBlock.BaseContent = BaseContent


export default EditorialBlock
