import React from 'react'
import { useDevice } from 'device'
import { cx } from 'helpers'
import { Text, Image } from 'components/dataDisplay'

import StyledTimer from 'compositions/StyledTimer/StyledTimer'
import messages from './messages'

import backgroundMobile from './images/background-mobile.png?src-loader'
import backgroundDesktop from './images/background-desktop.png?src-loader'
import caseDesktop from './images/case-desktop.png?src-loader'
import caseMobile from './images/case-mobile.png?src-loader'
import vialDesktop from './images/vial-desktop.png?src-loader'
import vialMobile from './images/vial-mobile.png?src-loader'


type ServiceAndOfferDescriptionDivisionAbOfferProps = {
  offerType: SubscriptionModule.OfferType
  discount?: string
  price?: string
  priceWithDiscount?: string
}

const ServiceAndOfferDescriptionDivisionOffer: React.FC<ServiceAndOfferDescriptionDivisionAbOfferProps> = (props) => {
  const { offerType, discount, price, priceWithDiscount } = props
  const { isMobile } = useDevice()

  const isFree = offerType === 'freeTrial' || offerType === 'free2ndMonth'

  return (
    <div
      className={
        cx(
          'absolute bottom-32 left-1/2 flex -translate-x-1/2 items-center py-12',
          isMobile ? 'h-[160rem] w-[386rem] pl-24 pr-8' : 'h-[128rem] w-[1174rem] pl-32 pr-24'
        )
      }
    >
      <div
        className={cx('absolute left-0 top-0 size-full bg-no-repeat', isMobile ? 'bg-[length:384rem_160rem]' : 'bg-[length:1174rem_128rem]')}
        style={{ backgroundImage: `url(${isMobile ? backgroundMobile.src : backgroundDesktop.src})` }}
      />
      <div className={isMobile ? undefined : 'flex'}>
        <div className={cx('relative', isMobile ? 'text-left' : 'mr-64')}>
          <Text
            message={offerType === 'free2ndMonth' ? messages.getSecondMonth : messages.try}
            style={isMobile ? 'h6' : 'h4'}
            color="gold-50"
            className={cx('whitespace-nowrap', isMobile && isFree || offerType === 'free2ndMonth' ? 'inline' : undefined)}
            html
          />
          {!isFree && price !== priceWithDiscount && (
            <Text
              className="inline font-normal line-through"
              style={isMobile ? 'h6' : 'h4'}
              color="black"
              html
            >
              {price}
            </Text>
          )}
          {(!isFree || !isMobile && offerType !== 'freeTrial' || offerType === 'free2ndMonth' || isMobile && offerType === 'freeTrial') && <>&nbsp;</>}
          <Text
            className="inline"
            style={isMobile ? 'h6' : 'h4'}
            color="black"
            message={isFree ? messages.free : undefined}
            children={isFree ? undefined : priceWithDiscount}
            html
          />
        </div>
        <div className={cx('relative flex', isMobile ? undefined : 'h-72 w-96 items-center')}>
          <StyledTimer
            withTitle
            label={messages.timer}
            titleClassname={isMobile ? 'text-left mt-8 mb-4' : 'text-center mb-8'}
            titleColor="black"
            titleStyle="t3"
            size={36}
            backgroundColor="gold-30"
            data-testid="timer"
          />
        </div>
      </div>
      <div
        className={
          cx(
            'relative flex flex-1 justify-end',
            isMobile ? 'flex-col items-end gap-8' : 'ml-[125rem] items-center gap-32'
          )
        }
      >
        <div className={cx('text-center', isMobile ? 'w-[128rem]' : undefined)}>
          {Boolean(offerType) ? (
            <>
              <Text
                message={{ ...messages.offer[offerType], values: { discount } }}
                style={isMobile || offerType === 'free2ndMonth' ? 'h5' : 'h3'}
                color="white"
                className={isMobile ? 'mb-4' : undefined}
                html
              />
              <Text message={messages.freeCase} style="sh6" color="white" className="whitespace-nowrap" />
            </>
          ) : (
            <Text message={messages.plusFreeCase} style="h3" color="white" className={isMobile ? 'mt-8' : undefined} html />
          )}
        </div>
        <div className="flex flex-col items-end">
          {
            offerType === 'freeProduct' && (
              <Image
                src={isMobile ? vialMobile : vialDesktop}
                alt="case"
                remWidth={isMobile ? 130 : 220}
                height={isMobile ? 28 : 54}
                className={cx('h-28 min-w-[130rem]', isMobile ? 'w-[130rem]' : 'mr-8 w-[220rem]')}
              />
            )
          }
          <Image
            src={isMobile ? caseMobile : caseDesktop}
            alt="case"
            remWidth={isMobile ? 130 : 256}
            height={isMobile ? 28 : 54}
            className={cx('h-28 min-w-[130rem]', isMobile ? '-mr-6' : undefined)}
          />
        </div>
      </div>
    </div>
  )
}

export default ServiceAndOfferDescriptionDivisionOffer
