import React, { useMemo } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { usePathname } from 'router'
import links from 'links'
import { constants, devicePx, twcx } from 'helpers'
import { useAb } from 'hooks'

import { Bone, WidthContainer } from 'components/layout'
import { HeroMediaBlock, Image, Text, type TextColor, type TextStyle } from 'components/dataDisplay'

import SubscribeButton from 'compositions/buttons/SubscribeButton/SubscribeButton'
import StyledTimer, { type BgColor, type Size } from 'compositions/StyledTimer/StyledTimer'

import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import GetStartedButton from '../GetStartedButton/GetStartedButton'
import SubscriptionOfferBadge from './components/SubscriptionOfferBadge/SubscriptionOfferBadge'
import ServiceAndOfferDescriptionDivisionOffer from './components/ServiceAndOfferDescriptionDivisionOffer/ServiceAndOfferDescriptionDivisionOffer'

import messages from './messages'

import defaultDesktopImage from './images/desktop-hero.jpg?src-loader'
import defaultMobileImage from './images/mobile-hero.jpg?src-loader'


type CustomHeroTextBlock = {
  className?: string
  style?: TextStyle
  color?: TextColor
  message?: string | Intl.Message
}

export type HeroSectionProps = {
  offerType: SubscriptionModule.OfferType
  price: string
  priceWithDiscount: string
  extraShippingPrice: string
  discount: string
  customBlocks: {
    preTitle?: CustomHeroTextBlock
    title?: CustomHeroTextBlock
    text?: CustomHeroTextBlock
    note?: CustomHeroTextBlock
    afterButtonText?: CustomHeroTextBlock
    timer?: {
      label?: string | Intl.Message
      size?: Size
      className?: string
      withLabel: boolean
      titleClassname?: string
      titleStyle?: TextStyle
      titleColor?: TextColor
      backgroundColor?: BgColor
      countdownColor?: TextColor
      captionColor?: TextColor
      forceShow?: boolean
    }
    logo?: Image.Src
    logoProps?: Omit<Image.Props, 'src'>
    background?: {
      src: string
      width: number
      height: number
      aspect: number
    }
    button?: HomePageCustomBlockButton
  }
  isFetching: boolean
  isTimerEnabled?: boolean
  startButtonMessage?: string | Intl.Message
  noteMessage?: string | Intl.Message
  offerBadge?: React.ReactNode
}

type MessagesByOffer = Record<SubscriptionModule.OfferType, { title: Intl.Message, text: Intl.Message, note?: Intl.Message }>

const messagesByOffer: MessagesByOffer = {
  freeTrial: messages.default.freeTrial,
  free2ndMonth: messages.default.freeMonth,
  freeProduct: messages.default.freeProduct,
  discount: messages.default.discount,
}

const messagesByOfferServiceDescriptionDivisionAndOfferAb: MessagesByOffer = {
  freeTrial: messages.serviceAndOfferDescriptionDivisionAb.freeTrial,
  free2ndMonth: messages.serviceAndOfferDescriptionDivisionAb.freeMonth,
  freeProduct: messages.serviceAndOfferDescriptionDivisionAb.freeProduct,
  discount: messages.serviceAndOfferDescriptionDivisionAb.discount,
}

const HeroSection: React.FunctionComponent<HeroSectionProps> = (props) => {
  const {
    customBlocks,
    discount,
    extraShippingPrice,
    isFetching,
    offerType,
    price,
    priceWithDiscount,
    isTimerEnabled,
    startButtonMessage,
    offerBadge,
  } = props

  const { isMobile, isDesktop } = useDevice()
  const pathname = usePathname()

  const isHeroServiceAndOfferDescriptionDivisionAbEnabled = useAb(constants.abTests.heroServiceAndOfferDescriptionDivision) === 'b'

  const withOfferBadge = offerType && ![ links.nift, links.ellimanperks, links.accessPerks ].includes(pathname) && !isHeroServiceAndOfferDescriptionDivisionAbEnabled

  const withTimer = Boolean(offerType && isTimerEnabled && !isHeroServiceAndOfferDescriptionDivisionAbEnabled || customBlocks?.timer?.forceShow)

  const heroImage = useMemo(() => {
    if (customBlocks?.background) {
      return customBlocks.background
    }

    return isMobile ? defaultMobileImage : defaultDesktopImage
  }, [ customBlocks?.background, isMobile ])


  let preTitle
  let titleMessage
  let textMessage
  let noteMessage
  let afterButtonText

  if (customBlocks) {
    preTitle = customBlocks.preTitle?.message
    titleMessage = customBlocks.title?.message
    if (customBlocks.text?.message) {
      textMessage = typeof customBlocks.text?.message === 'string' ? customBlocks.text?.message : {
        ...customBlocks.text?.message,
        values: {
          price,
          priceWithDiscount,
          ...customBlocks.text?.message.values,
        },
      }
    }
    noteMessage = customBlocks.note?.message
    afterButtonText = customBlocks.afterButtonText?.message
  }
  else {
    const title = isHeroServiceAndOfferDescriptionDivisionAbEnabled ? (
      messagesByOfferServiceDescriptionDivisionAndOfferAb[offerType]?.title || messages.serviceAndOfferDescriptionDivisionAb.noDiscount.title
    ) : (
      messagesByOffer[offerType]?.title || messages.default.noDiscount.title
    )

    titleMessage = {
      ...title,
      values: {
        discount,
        price,
        priceWithDiscount: priceWithDiscount || price,
        fragrancesAmount: constants.businessLogic.fragrancesAmount,
      },
    }

    const text = isHeroServiceAndOfferDescriptionDivisionAbEnabled ? (
      messagesByOfferServiceDescriptionDivisionAndOfferAb[offerType]?.text || messages.serviceAndOfferDescriptionDivisionAb.noDiscount.text
    ) : (
      messagesByOffer[offerType]?.text || messages.default.noDiscount.text
    )

    textMessage = {
      ...text,
      values: {
        price,
        extraShippingPrice,
        priceWithDiscount: priceWithDiscount || price,
      },
    }
  }

  const withLogo = Boolean(customBlocks?.logo)
  const isTimerWithLabel = (
    customBlocks?.timer === undefined || customBlocks?.timer?.withLabel
  )

  const timerClassName = customBlocks?.timer?.className ?? (!noteMessage || !isTimerWithLabel ? 'mt-16' : null)
  const timerBackgroundColor = customBlocks?.timer?.backgroundColor ?? undefined
  const timerCountdownColor = customBlocks?.timer?.countdownColor ?? undefined
  const timerCaptionColor = customBlocks?.timer?.captionColor ?? undefined
  const timerSize = customBlocks?.timer?.size ?? 56
  const timerTitleClassname = customBlocks?.timer?.titleClassname ?? 'mb-12'
  const timerTitleStyle = customBlocks?.timer?.titleStyle ?? (isMobile ? 'p5' : 'p1')
  const timerTitleColor = customBlocks?.timer?.titleColor ?? 'black'

  const preTitleStyle = customBlocks?.preTitle?.style ?? (isMobile ? 'p1' : 'sh3')
  const titleStyle = customBlocks?.title?.style ?? (isMobile ? 'h4' : 'h2')
  const textStyle = customBlocks?.text?.style ?? (isMobile ? 'p2' : 'sh3')
  const noteStyle = customBlocks?.note?.style ?? (isMobile ? 't1' : 'sh3')
  const afterButtonTextStyle = customBlocks?.afterButtonText?.style ?? 'sh6'

  const preTitleColor = customBlocks?.preTitle?.color ?? undefined
  const titleColor = customBlocks?.title?.color ?? undefined
  const textColor = customBlocks?.text?.color ?? undefined
  const noteColor = customBlocks?.note?.color ?? 'white'
  const afterButtonTextColor = customBlocks?.afterButtonText?.color ?? undefined

  const preTitleClassname = customBlocks?.preTitle?.className ?? 'mb-20 text-white'
  const titleClassname = customBlocks?.title?.className ?? 'max-w-560'
  const textClassname = customBlocks?.text?.className ?? (isMobile ? `mt-16 mx-auto` : 'mt-24 max-w-464')
  const noteClassname = customBlocks?.note?.className ?? (isMobile ? 'mt-8' : cx('mt-16 max-w-464 pr-64', withTimer ? 'whitespace-nowrap' : 'whitespace-pre-line'))
  const afterButtonTextClassname = customBlocks?.afterButtonText?.className ?? (isMobile ? 'mt-8' : 'mt-20')

  const ButtonComponent = customBlocks?.button?.actionType === 'subscribe' ? SubscribeButton : GetStartedButton
  const buttonProps = {
    title: startButtonMessage,
    size: 56 as const,
    width: 280 as const,
    className: withTimer || isHeroServiceAndOfferDescriptionDivisionAbEnabled ? 'mt-32' : 'mt-56',
    ...customBlocks?.button,
  }

  let mobileMinHeight
  if (withLogo) {
    mobileMinHeight = '760rem'
  }
  else if (withTimer) {
    mobileMinHeight = '686rem'
  }
  else {
    mobileMinHeight = '608rem'
  }

  const containerClassName = twcx(
    'relative overflow-hidden bg-beige',
    isMobile && 'pt-24 text-center'
  )

  const { className: logoClassName, ...logoProps } = customBlocks?.logoProps ?? {}

  return (
    <HeroMediaBlock className={containerClassName} data-testid="heroSection">
      <Image
        className={twcx('pointer-events-none object-cover', isMobile && 'object-bottom')}
        src={heroImage.src}
        sizes={isMobile ? '100vw' : '(min-width: 1440px) 1440px, (min-width: 1080px) 100vw, 1080px'}
        quality={90}
        alt="Scentbird vial and case"
        fill
        priority
      />
      <div
        className={twcx('relative flex h-full', !isMobile && 'items-center justify-start')}
        style={{ minHeight: isMobile ? mobileMinHeight : '768rem' }}
      >
        {
          isFetching ? (
            <WidthContainer className={isMobile ? null : 'w-full max-w-464'}>
              <Bone
                className={cx('w-full max-w-336 rounded', isMobile && 'mx-auto')}
                h={isMobile ? 20 : 40}
                bgColor="gray-70"
              />
              <Bone
                className={cx('mt-12 rounded', isMobile && 'mx-auto')}
                w={isMobile ? 200 : 400}
                h={isMobile ? 20 : 40}
                bgColor="gray-70"
              />
              <Bone
                className={cx(isMobile ? 'mx-auto mt-16' : 'mt-32')}
                w={isMobile ? 240 : 280}
                h={isMobile ? 38 : 56}
                bgColor="gray-70"
              />
            </WidthContainer>
          ) : (
            <WidthContainer
              ignore={isMobile}
              className={
                twcx(
                  'w-full',
                  isMobile && 'flex flex-col px-16',
                  isHeroServiceAndOfferDescriptionDivisionAbEnabled && 'px-8'
                )
              }
            >
              {
                withLogo && (
                  <Image
                    className={twcx(isMobile ? 'mb-16 max-h-80 self-center' : 'mb-24 max-h-[110rem]', logoClassName)}
                    src={customBlocks?.logo}
                    alt="Logo"
                    height={isMobile ? devicePx(80) : 110}
                    {...logoProps}
                  />
                )
              }
              {
                Boolean(preTitle) && (
                  <Text
                    className={preTitleClassname}
                    message={preTitle}
                    style={preTitleStyle}
                    color={preTitleColor}
                    html
                  />
                )
              }
              <Text
                className={titleClassname}
                message={titleMessage}
                style={titleStyle}
                color={titleColor}
                html
              />
              {
                Boolean(textMessage) && (
                  <Text
                    className={textClassname}
                    message={textMessage}
                    style={textStyle}
                    color={textColor}
                    html
                  />
                )
              }
              {
                Boolean(noteMessage) && (
                  <Text
                    className={noteClassname}
                    message={noteMessage}
                    style={noteStyle}
                    color={noteColor}
                    html
                  />
                )
              }
              {
                withTimer && (
                  <StyledTimer
                    className={timerClassName}
                    size={timerSize}
                    label={customBlocks?.timer?.label}
                    backgroundColor={timerBackgroundColor}
                    countdownColor={timerCountdownColor}
                    captionColor={timerCaptionColor}
                    withTitle={isTimerWithLabel}
                    titleClassname={timerTitleClassname}
                    titleStyle={timerTitleStyle}
                    titleColor={timerTitleColor}
                    data-testid="timer"
                  />
                )
              }
              {
                isDesktop && (
                  <ButtonComponent
                    {...buttonProps}
                    placement="Hero banner"
                  />
                )
              }
              {
                Boolean(afterButtonText) && (
                  <Text
                    className={afterButtonTextClassname}
                    message={afterButtonText}
                    style={afterButtonTextStyle}
                    color={afterButtonTextColor}
                    html
                  />
                )
              }
              {
                withOfferBadge && !offerBadge && (
                  <SubscriptionOfferBadge
                    className={twcx(
                      'absolute',
                      isMobile ? 'bottom-80 right-[132rem]' : 'bottom-[224rem] left-auto right-[273rem]'
                    )}
                    offerType={offerType}
                    discount={discount}
                  />
                )
              }
              {
                isHeroServiceAndOfferDescriptionDivisionAbEnabled && (
                  <ServiceAndOfferDescriptionDivisionOffer
                    offerType={offerType}
                    discount={discount}
                    price={price}
                    priceWithDiscount={priceWithDiscount || price}
                  />
                )
              }
              {offerBadge}
            </WidthContainer>
          )
        }
      </div>
    </HeroMediaBlock>
  )
}


export default React.memo(HeroSection)
