import React, { useEffect } from 'react'
import { useLandingSetup } from 'hooks'
import links from 'links'
import { track } from 'analytics'
import { Helmet } from 'react-helmet-async'
import { usePathname } from 'router'

import PageSkeleton from 'compositions/PageSkeleton/PageSkeleton'

import View from '../View/View'

import { useLandingCouponCode, useLandingCustomHeroBlocks, useLandingCustomProductsBlock, useLandingCustomButtons } from './util'


const LandingPage: React.FunctionComponent = () => {
  const pathname = usePathname()

  const { couponCode, country } = useLandingCouponCode()

  const canonicalPath = /men\//.test(pathname) ? links.homeMen : links.home

  const {
    offerType,
    eventDiscountText,
    formattedPrice,
    formattedPriceWithDiscount,
    discountAmount,
    discountPercent,
    formattedExtraShippingPrice,
    isFetching,
  } = useLandingSetup({
    couponCode,
    country,
  })

  const customHeroBlocks = useLandingCustomHeroBlocks({
    offerType,
    price: formattedPrice,
    priceWithDiscount: formattedPriceWithDiscount,
  })
  const customProductsBlock = useLandingCustomProductsBlock()
  const customBlocksButtons = useLandingCustomButtons({
    price: formattedPrice,
    priceWithDiscount: formattedPriceWithDiscount,
  })

  useEffect(() => {
    if (!eventDiscountText) {
      return
    }

    track('Home page', {
      discount: eventDiscountText,
      coupon: couponCode,
    })
  }, [ eventDiscountText ]) // eslint-disable-line react-hooks/exhaustive-deps


  if (isFetching) {
    return (
      <PageSkeleton />
    )
  }

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://www.scentbird.com${canonicalPath}`} />
      </Helmet>
      <View
        eventDiscountText={eventDiscountText}
        offerType={offerType}
        price={formattedPrice}
        priceWithDiscount={formattedPriceWithDiscount}
        extraShippingPrice={formattedExtraShippingPrice}
        discount={discountAmount || discountPercent}
        couponCode={couponCode}
        heroCustomBlocks={customHeroBlocks}
        customProductsBlock={customProductsBlock}
        customBlocksButtons={customBlocksButtons}
        isFetching={isFetching}
      />
    </>
  )
}


export default LandingPage
