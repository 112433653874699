import React from 'react'
import links from 'links'
import { useDevice } from 'device'

import { track } from 'analytics'
import { Button, type ButtonProps } from 'components/inputs'

import messages from './messages'


type GetStartedButtonProps = Pick<ButtonProps, 'width' | 'fullWidth' | 'fullWidthOnMobile'> & {
  className?: string
  size?: Extract<ButtonProps['size'], 38 | 56>
  style?: Extract<ButtonProps['style'], 'primary' | 'gold-30'>
  placement: string
  title?: Intl.Message | string
}

const GetStartedButton: React.FunctionComponent<GetStartedButtonProps> = (props) => {
  const { className, size = 56, style = 'primary', width, fullWidth, fullWidthOnMobile, placement, title } = props

  const { isMobile } = useDevice()

  return (
    <Button
      className={className}
      title={title ?? messages.quiz}
      inlineIcon={isMobile ? '32/arrow-right' : null}
      size={size}
      to={links.smartRecommendations}
      style={style}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      html
      onClick={() => {
        track('Banner click', {
          placement,
          content: 'Quiz',
          link: links.smartRecommendations,
          action: 'link',
        })
      }}
      data-testid="getStartedButton"
    />
  )
}


export default GetStartedButton
