import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { Text } from 'components/dataDisplay'

import messages from './messages'


type SubscriptionOfferBadgeProps = {
  className?: string
  offerType: SubscriptionModule.OfferType
  discount?: string
}

const SubscriptionOfferBadge: React.FC<SubscriptionOfferBadgeProps> = (props) => {
  const { className, offerType, discount } = props
  const { isMobile } = useDevice()

  const defaultOfferTextSize = isMobile ? 'text-[33rem]/[31rem]' : 'text-[48rem]/[46rem]'
  const smallOfferTextSize = isMobile ? 'text-[19rem]/[20rem]' : 'text-[31rem]/[32rem]'
  const offerTextSize = offerType === 'discount' || offerType === 'freeTrial' ? defaultOfferTextSize : smallOfferTextSize

  return (
    <div
      className={cx(
        className,
        'absolute rounded-full bg-black font-tt-norms-pro font-bold uppercase',
        isMobile ? 'size-[144rem] p-6' : 'size-[199rem] p-8'
      )}
      style={{
        background: 'linear-gradient(147.02deg, #303030 22.99%, #000000 79.73%)',
      }}
    >
      <div className="flex h-full items-center justify-center rounded-full border border-pink text-center">
        <div>
          <Text
            className={offerTextSize}
            message={{ ...messages.offer[offerType], values: { discount } }}
            style={null}
            color="gold-30"
            html
          />
          <Text
            className={cx('mt-4', isMobile ? 'text-[12rem]/[12rem]' : 'text-[16rem]/[16rem]')}
            message={messages.freeCase}
            style={null}
            color="white"
            html
          />
        </div>
      </div>
    </div>
  )

}

export default SubscriptionOfferBadge
