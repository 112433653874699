import React from 'react'
import links from 'links'
import { useDevice } from 'device'
import { track } from 'analytics'

import { Button } from 'components/inputs'
import BottomBannerBase from 'compositions/landings/BottomBanner/BottomBanner'
import SubscribeButton from 'compositions/buttons/SubscribeButton/SubscribeButton'

import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import messages from './messages'


type BottomBannerProps = {
  className?: string
  customButton?: HomePageCustomBlockButton
  startButtonMessage?: string | Intl.Message
}

const BottomBanner: React.FunctionComponent<BottomBannerProps> = (props) => {
  const { className, startButtonMessage, customButton } = props
  const { isMobile } = useDevice()

  let button: React.ReactNode

  if (customButton) {
    const ButtonComponent = customButton?.actionType === 'subscribe' ? SubscribeButton : GetStartedButton
    const buttonProps = {
      title: startButtonMessage,
      style: 'gold-30' as const,
      size: 56 as const,
      fullWidth: true,
      ...customButton,
    }

    button = (
      <ButtonComponent {...buttonProps} placement="Page bottom" />
    )
  }
  else {
    button = isMobile ? (
      <GetStartedButton
        title={startButtonMessage}
        style="gold-30"
        fullWidth
        placement="Page bottom"
      />
    ) : (
      <Button
        title={startButtonMessage || messages.buttonTitle}
        to={links.subscribe}
        size={56}
        width={320}
        onClick={() => {
          track('Banner click', {
            placement: 'Page bottom',
            content: 'Subscribe',
            action: 'link',
            link: links.subscribe,
          })
        }}
      />
    )
  }

  return (
    <BottomBannerBase
      className={className}
      button={button}
    />
  )
}


export default BottomBanner
