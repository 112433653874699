export default {
  nift: {
    title: {
      en: 'Get your first month<br class="onlyMobile"/> for less than $7',
    },
    text: {
      // eslint-disable-next-line max-len
      en: 'Use your <strong>$30</strong> Nift gift certificate to score a new designer scent (<strong>{price}</strong>), travel case (<strong>$15.95</strong>), and shipping (<strong>$3.99</strong>) for just <strong>{priceWithDiscount}</strong>. And if you love Scentbird, indulge in new designer scents every month after for just <strong>{price}</strong> per month.',
    },
    note: {
      en: 'You may update your preferences or cancel<br class="onlyMobile"/> at any time.',
    },
  },
  bouqs: {
    title: {
      en: 'Get 60% off and a free case',
    },
    text: {
      en: 'Get your first month for less than <strong>$7</strong> courtesy of Bouqs. Indulge in new designer scents every month after for just <strong>{price}</strong>.',
    },
    note: {
      en: 'Upgrade or cancel at any time.  Deal ends in:',
    },
  },
  andreDrummond: {
    title: {
      en: `
        Get 50% off<br /> and a free<br class="onlyDesktop"/> case
      `,
    },
    text: {
      en: `
        Step up your fragrance game like a<br class="onlyDesktop" /> pro with<br class="onlyMobile" />
        new designer scents every<br class="onlyDesktop" /> month after<br class="onlyMobile" /> for just {price}.<br class="onlyDesktop" />
        <span class="{afterTextStyle}">Get your first month for {priceWithDiscount}.</span>
      `,
    },
    note: {
      en: 'Deal ends in:',
    },
    button: {
      en: 'Try first month for {price}',
    },
    shortButton: {
      en: 'Try for {price}',
    },
    buttonWithDiscount: {
      en: 'Try first month for {priceWithDiscount} <s>{price}</s>',
    },
    productsBlockTitle: {
      en: `
        Select what you'd<br class="onlyMobile" /> like to try<br class="onlyDesktop" /> from andre drummond’s<br class="onlyMobile" /> pro picks
      `,
    },
  },
  douglasElliman: {
    title: {
      en: 'Get {discount}% off and a Free Case',
    },
    text: {
      en: 'Get your first month for less than {priceWithDiscount} courtesy of Douglas Elliman. Indulge in new designer scents every month after for just {price}',
    },
    quiz: {
      en: 'Get started with a quiz',
    },
    note: {
      en: 'Update or cancel at anytime',
    },
  },
  accessDevelopment: {
    title: {
      en: 'Get {discount}% off and a Free Case',
    },
    text: {
      en: 'Get your first month for less than {priceWithDiscount} courtesy of Access. Indulge in new designer scents every month after for just {price}',
    },
    quiz: {
      en: 'Get started with a quiz',
    },
    note: {
      en: 'Update or cancel at anytime',
    },
  },
}
