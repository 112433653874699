import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'

import SubscribeButton from 'compositions/buttons/SubscribeButton/SubscribeButton'
import BrandsQualityBlock from 'compositions/landings/BrandsQualityBlock/BrandsQualityBlock'

import type { HomePageCustomBlockButton } from 'pages/landing/View/View'

import GetStartedButton from '../GetStartedButton/GetStartedButton'


type BrandsSectionProps = {
  className?: string
  customButton?: HomePageCustomBlockButton
  startButtonMessage?: string | Intl.Message
}

const BrandsSection: React.FunctionComponent<BrandsSectionProps> = (props) => {
  const { className, startButtonMessage, customButton } = props
  const { isMobile } = useDevice()

  const ButtonComponent = customButton?.actionType === 'subscribe' ? SubscribeButton : GetStartedButton
  const buttonProps = {
    title: startButtonMessage,
    size: 56 as const,
    width: (isMobile ? null : 280 as const),
    fullWidthOnMobile: true,
    className: isMobile ? 'mt-24' : 'mt-32',
    ...customButton,
  }

  const button = (
    <ButtonComponent
      {...buttonProps}
      placement="Brand block"
    />
  )

  return (
    <BrandsQualityBlock
      className={cx(className, isMobile && 'pb-64')}
      button={button}
    />
  )
}


export default React.memo(BrandsSection)
