import cookieStorage from 'cookie-storage'
import { useFt, useFlow } from 'hooks'
import links from 'links'
import { useParams, usePathname, useSearchParams } from 'router'
import { constants } from 'helpers'
import { getCouponFromQueryParams } from 'helpers/getters'
import { useLocalePreferences } from 'modules/localePreferences'

import getForcedCouponByPathname from './getForcedCouponByPathname'


const useLandingCouponCode = () => {
  const pathname = usePathname()
  const { cpn: pathCoupon } = useParams()
  const [ { cpn: queryCoupon, utm_campaign: utmCampaign } ] = useSearchParams()
  const { localePreferences } = useLocalePreferences()

  const isDefaultCoupon50Enabled = useFt(constants.features.defaultCoupon50)
  const isHoliday22Sub60Enabled = useFt(constants.features.holiday22Sub60)

  const isCanada = (
    pathname === links.freeTrialCa
    || pathname === links.freeTrialMenCa
    || Boolean(utmCampaign?.match(/canada/i))
  )

  // TODO in this case country can change, so we need to change current locale, or refresh the page - added on 2024-05-19 by maddoger
  const country: Intl.CountryCode = isCanada ? 'CA' : localePreferences.country

  const { isInfluencerFlow } = useFlow()

  const coupon = pathCoupon || queryCoupon

  let couponCode = getForcedCouponByPathname(pathname)

  if (!couponCode && coupon) {
    couponCode = getCouponFromQueryParams(coupon)
  }

  const cookieCouponCode = cookieStorage.getItem(constants.cookieNames.discountCoupon)

  // queryCoupon may be null for 'none' param and undefined in case of invalid param
  if (couponCode === undefined) {
    if (cookieCouponCode !== undefined) {
      couponCode = cookieCouponCode
    }
    else if (isInfluencerFlow) {
      couponCode = 'HDR55INF'
    }
    // special case for Canada
    else if (isCanada) {
      couponCode = 'HD60CA'
    }
    else if (isHoliday22Sub60Enabled) {
      couponCode = 'HD60'
    }
    else if (isDefaultCoupon50Enabled) {
      couponCode = 'HD50'
    }
    else {
      couponCode = 'HD25'
    }
  }

  return {
    couponCode,
    country,
  }
}


export default useLandingCouponCode
