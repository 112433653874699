import React from 'react'
import { useDevice } from 'device'

import { RichText, Text, type TextStyle } from 'components/dataDisplay'
import { Button, buttonMessages, type ButtonProps } from 'components/inputs'


export type BaseContentProps = {
  supTitle?: string | Intl.Message
  title?: string | Intl.Message
  titleStyle?: TextStyle
  text?: string | Intl.Message
  richText?: string | Strapi.RichTextState
  buttonProps?: {
    title?: string | Intl.Message
    inlineIcon?: ButtonProps['inlineIcon']
    'data-testid'?: string
  } & AtLeastOne<{
    to: string
    onClick: React.MouseEventHandler
  }>
}

const BaseContent: React.FunctionComponent<BaseContentProps> = (props) => {
  const { supTitle, title, titleStyle, text, buttonProps, richText } = props

  const { isMobile } = useDevice()

  const baseTitleStyle = isMobile ? 'h4' : 'h3'

  return (
    <>
      {
        Boolean(supTitle) && (
          <Text
            className="mb-16"
            message={supTitle}
            style="eye1"
            color="gold-30"
          />
        )
      }
      {
        Boolean(title) && (
          <Text
            message={title}
            style={titleStyle || baseTitleStyle}
            color="white"
            html
          />
        )
      }
      {
        Boolean(text) && (
          <Text
            className="mt-16"
            message={text}
            style="p1"
            color="white"
          />
        )
      }
      {
        Boolean(richText) && (
          <RichText
            className="mt-16"
            message={richText}
          />
        )
      }
      {
        Boolean(buttonProps) && (
          <Button
            className={isMobile ? 'mt-16' : 'mt-24'}
            title={buttonProps.title || buttonMessages.details}
            inlineIcon={buttonProps.inlineIcon}
            to={buttonProps.to}
            style="gold-30"
            size={56}
            width={320}
            fullWidthOnMobile
            onClick={buttonProps.onClick}
            data-testid={buttonProps['data-testid']}
          />
        )
      }
    </>
  )
}


export default React.memo(BaseContent)
