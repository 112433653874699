import React from 'react'
import { track } from 'analytics'
import links from 'links'
import { useSubscribeOffer } from 'modules/subscription'
import type { ButtonProps } from 'components/inputs'
import { Button, buttonMessages } from 'components/inputs'


export type SubscribeButtonProps =
  Partial<Pick<ButtonProps, 'className' | 'title' | 'style' | 'size' | 'width' | 'fullWidth' | 'fullWidthOnMobile' | 'loading' | 'onClick' | 'data-testid'>>
  & {
    placement: string
  }

const SubscribeButton: React.FunctionComponent<SubscribeButtonProps> = (props) => {
  const {
    className,
    title,
    style = 'primary',
    size = 56,
    width,
    fullWidth,
    fullWidthOnMobile,
    placement,
    loading,
    'data-testid': dataTestId = 'subscribeButton',
    onClick,
  } = props

  const { offerType, formattedPrice, formattedPriceWithDiscount, formattedExtraShippingPrice, eventDiscountText,
    couponCode: coupon, isFetching, handleSubscribeClick } = useSubscribeOffer()

  const freeTrialPrice = offerType === 'freeTrial' ? formattedExtraShippingPrice : null
  const price = freeTrialPrice || formattedPriceWithDiscount || formattedPrice

  return (
    <Button
      className={className}
      title={title || { ...buttonMessages.subscribeForPrice, values: { price } }}
      size={size}
      style={style}
      width={width}
      fullWidth={fullWidth}
      fullWidthOnMobile={fullWidthOnMobile}
      loading={isFetching || loading}
      to={links.subscribe}
      onClick={(event) => {
        track('Banner click', {
          placement,
          content: 'Subscribe',
          action: 'link',
          link: links.subscribe,
          discount: eventDiscountText,
          coupon,
        })

        handleSubscribeClick()

        if (typeof onClick === 'function') {
          onClick(event)
        }
      }}
      // TODO: Improve the flow by making sure that this property is used only within the product description — added on 12–02–2024 by algeas
      data-cnstrc-btn="subscribe"
      data-testid={dataTestId}
      html
    />
  )
}


export default SubscribeButton

